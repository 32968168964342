import {formErrors} from 'quickstart/utils/final-form-errors'
import {useEffect, useRef} from 'react'
import * as Final from 'react-final-form'
import {FormErrors, isEmptyObj, logger} from 'tizra'
import * as B from '../../block'
import {StepComponent} from '../types'

const log = logger('CartBlock/CheckoutStep')

export const SimpleFormStep: StepComponent = ({
  active,
  step: _step,
  submit: _submit,
}) => {
  const step = _step!
  const infoRequired = step.infoRequired || []
  const buttonPrompt = step.buttonPrompt || 'Continue'

  const initialValues = Object.fromEntries(
    infoRequired.map(f => [f.name, f.defaultValue]),
  )

  const submit = async (values: any) => {
    const data = await _submit(step.name, values)
    let {errors, message, reason, status} = (data || {}) as FormErrors
    if (reason === 'validation' && errors && !isEmptyObj(errors)) {
      message = reason = undefined
    }
    return formErrors({errors, message, reason, status})
  }

  const firstInputRef = useRef<HTMLInputElement>(undefined)
  const firstInput = firstInputRef.current
  useEffect(() => void (active && firstInput?.focus()), [active, firstInput])

  return (
    <Final.Form
      initialValues={initialValues}
      onSubmit={submit}
      render={({handleSubmit}) => (
        <form
          onSubmit={handleSubmit}
          style={{display: active ? undefined : 'none'}}
        >
          <B.FormShell
            variant="fluid"
            primaryButton={{type: 'submit', children: buttonPrompt}}
          >
            {infoRequired.map(({name, optional, prompt}, i) => (
              <B.ConnectedField
                key={name}
                component={B.Input}
                name={name}
                label={prompt}
                required={!optional}
                ref={i ? undefined : firstInputRef}
              />
            ))}
          </B.FormShell>
        </form>
      )}
    />
  )
}
