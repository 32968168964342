import {useEffect, useState} from 'react'
import {logger} from 'tizra'
import * as B from '../block'
import {StepComponent} from './types'

const log = logger('CartBlock/FulfillerButtons')

export const FulfillerButtons: StepComponent = ({active}) => {
  const [payPalForm, setPayPalForm] = useState<HTMLFormElement | null>(null)
  const [testForm, setTestForm] = useState<HTMLFormElement | null>(null)
  const [freeLink, setFreeLink] = useState<HTMLAnchorElement | null>(null)

  useEffect(() => {
    setPayPalForm(document.forms.namedItem('payPalForm'))
    setTestForm(
      document.querySelector(
        'form[action="/commerce/fulfiller_hardcoded"]',
      ) as HTMLFormElement,
    )
    setFreeLink(
      document.querySelector(
        'a[href^="/commerce/"][href$="/fulfiller_free"]',
      ) as HTMLAnchorElement,
    )
  }, [])

  if (!payPalForm && !testForm && !freeLink) {
    return null
  }

  if (!active) {
    return null
  }

  return (
    <B.Stack spacing="xl" divided>
      {payPalForm && (
        <B.Stack spacing="xl">
          <B.Text>
            No PayPal account required—you will have the option at the PayPal
            site to use a credit card.
          </B.Text>
          <B.Button onClick={() => void payPalForm.submit()}>
            Purchase with PayPal
          </B.Button>
        </B.Stack>
      )}
      {freeLink && (
        <B.Stack spacing="xl">
          <B.Button onClick={() => void freeLink.click()}>
            Complete free purchase
          </B.Button>
        </B.Stack>
      )}
      {testForm && (
        <B.Stack spacing="xl">
          <B.Button onClick={() => void testForm.submit()}>
            Purchase with Test Fulfiller
          </B.Button>
        </B.Stack>
      )}
    </B.Stack>
  )
}
