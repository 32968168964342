import {formErrors} from 'quickstart/utils/final-form-errors'
import * as R from 'rambdax'
import {ComponentPropsWithoutRef, useEffect, useRef} from 'react'
import * as Final from 'react-final-form'
import {FormErrors, isEmptyObj, logger} from 'tizra'
import * as B from '../../block'
import {StepComponent} from '../types'
import {countries, states} from './cokesbury'

const log = logger('CartBlock/UmphBillingStep')

type SK = keyof typeof states

const UMPH_FIELD_NAMES = [
  'first-name',
  'last-name',
  'email',
  'address1',
  'address2',
  'country',
  'city',
  'state',
  'postal-code',
  'phone',
] as const

type UmphFieldName = (typeof UMPH_FIELD_NAMES)[number]

export const UmphBillingStep: StepComponent = ({
  active,
  step: _step,
  submit: _submit,
}) => {
  const step = _step!
  const infoRequired = step.infoRequired || []
  const buttonPrompt = step.buttonPrompt || 'Continue'

  const initialValues = Object.fromEntries(
    infoRequired.map(f => [f.name, f.defaultValue]),
  )

  const submit = async (values: any) => {
    const data = await _submit(step.name, values)
    let {errors, message, reason, status} = (data || {}) as FormErrors
    if (reason === 'validation' && errors && !isEmptyObj(errors)) {
      message = reason = undefined
    }
    return formErrors({errors, message, reason, status})
  }

  const firstInputRef = useRef<HTMLInputElement>(undefined)
  const firstInput = firstInputRef.current
  useEffect(() => void (active && firstInput?.focus()), [active, firstInput])

  const inp = (
    name: UmphFieldName,
    {
      autoComplete,
      label,
      ...props
    }: ComponentPropsWithoutRef<'input'> & {label?: string; ref?: any} = {},
  ) => {
    const field = infoRequired.find(f => f.name === name)
    return (
      field && (
        <B.ConnectedField
          key={name}
          component={B.Input}
          name={name}
          label={label || field.prompt}
          required={!field.optional}
          {...props}
          autoComplete={autoComplete && `billing ${autoComplete}`}
        />
      )
    )
  }

  const sel = (
    name: UmphFieldName,
    {
      label,
      ...props
    }: Omit<ComponentPropsWithoutRef<typeof B.Select> & {ref?: any}, 'name'> & {
      label?: string
    },
  ) => {
    const field = infoRequired.find(f => f.name === name)
    return (
      field && (
        <B.ConnectedField
          key={name}
          component={B.Select}
          name={name}
          label={label || field.prompt}
          required={!field.optional}
          {...props}
        />
      )
    )
  }

  const extraInfoRequired = R.omit(UMPH_FIELD_NAMES as any, infoRequired)

  return (
    <Final.Form
      initialValues={initialValues}
      onSubmit={submit}
      render={({handleSubmit, values, values: {country}}) => {
        const c = country || 'USA'
        return (
          <>
            <B.AddressSummary
              values={{
                ...values,
                firstName: values['first-name'],
                lastName: values['last-name'],
                zip: values['postal-code'],
                country: values['country'],
              }}
              style={{display: !active ? undefined : 'none'}}
            />
            <form
              onSubmit={handleSubmit}
              style={{display: active ? undefined : 'none'}}
            >
              <B.FormShell
                variant="fluid"
                primaryButton={{type: 'submit', children: buttonPrompt}}
              >
                <B.Flex>
                  {inp('first-name', {
                    autoComplete: 'given-name',
                    ref: firstInputRef,
                  })}
                  {inp('last-name', {autoComplete: 'family-name'})}
                </B.Flex>
                {sel('country', {
                  //autoComplete: 'country', // B.Select doesn't accept
                  options: countries,
                })}
                {inp('address1', {
                  autoComplete: 'address-line1',
                  label: 'Street address',
                })}
                {inp('address2', {
                  autoComplete: 'address-line2',
                  label: 'Apartment, suite, etc. (optional)',
                })}
                <B.Flex>
                  {inp('city')}
                  {c in states ?
                    sel('state', {
                      label: c === 'CAN' ? 'Province' : 'State',
                      options: states[c as SK],
                    })
                  : inp('state')}
                  {inp('postal-code', {
                    label: c === 'USA' ? 'Zip code' : 'Postal code',
                  })}
                </B.Flex>
                {inp('email', {type: 'email', autoComplete: 'email'})}
                {inp('phone', {type: 'tel', autoComplete: 'tel'})}
                {Object.keys(extraInfoRequired).map(name =>
                  inp(name as UmphFieldName),
                )}
              </B.FormShell>
            </form>
          </>
        )
      }}
    />
  )
}
