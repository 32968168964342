import {ReactNode, useMemo, useState} from 'react'
import {CheckoutStep as TCheckoutStep, logger, truthy} from 'tizra'
import {SetRequired} from 'type-fest'
import * as B from '../block'
import {CheckoutStep} from './CheckoutStep'
import {FulfillerButtons} from './FulfillerButtons'
import {PickCheckout} from './PickCheckout'
import {SignIn} from './SignIn'
import {Config} from './admin'
import * as S from './styles'
import {StepComponent} from './types'

const log = logger('CartBlock/CheckoutWizard')

const CIRCLED_NUMBERS = [
  '⓪',
  '①',
  '②',
  '③',
  '④',
  '⑤',
  '⑥',
  '⑦',
  '⑧',
  '⑨',
] as const

const NumberedHeading = ({
  number,
  children,
}: {
  children: ReactNode
  number: number
}) => (
  <S.NumberedHeading>
    <div>{CIRCLED_NUMBERS[number]}</div>
    <div>{children}</div>
  </S.NumberedHeading>
)

interface CheckoutWizardProps extends SetRequired<B.UseCheckoutReturn, 'meta'> {
  config: Config
  userData: Exclude<ReturnType<typeof B.useUserData>, null>
}

export const CheckoutWizard = ({
  config,
  userData,
  meta,
  checkoutName,
  checkout,
  firstIncomplete,
  ...props
}: CheckoutWizardProps) => {
  const showPicker = meta.checkouts.length !== 1

  const [_index, _setIndex] = useState<number>()
  const firstIncompleteIndex = (showPicker ? 2 : 1) + (firstIncomplete ?? 0)
  const currentIndex =
    _index ??
    (!userData ? 0
    : !checkoutName ? 1
    : firstIncompleteIndex)
  const setIndex: typeof _setIndex = (...args) => {
    if (args[0] === -1) {
      _setIndex(firstIncompleteIndex)
    } else {
      _setIndex(...args)
    }
  }

  const steps = useMemo<
    Array<{title: string; Component: StepComponent; step?: TCheckoutStep}>
  >(() => {
    const checkoutSteps =
      checkout?.steps
        .filter(step => !step.hidden)
        .map(step => ({
          title: step.displayName ?? step.name,
          Component:
            step.name === B.FULFILLER_BUTTONS_STEP_NAME ?
              FulfillerButtons
            : CheckoutStep,
          step,
        })) || []
    return [
      {title: 'Sign in', Component: SignIn},
      showPicker && {
        title: 'Checkout method',
        Component: PickCheckout,
      },
      ...checkoutSteps,
    ].filter(truthy)
  }, [checkout, showPicker])

  return (
    <B.Stack divided endCapped spacing="lg">
      {steps.map(({title, Component, step}, myIndex) => {
        const active = currentIndex === myIndex
        const canEdit = currentIndex > myIndex
        return (
          <B.Stack key={myIndex}>
            <B.LeftRight>
              <NumberedHeading number={myIndex + 1}>{title}</NumberedHeading>
              <B.Text variant="textMd">
                {canEdit ?
                  <B.Link onClick={() => setIndex(myIndex)}>Edit</B.Link>
                : <span style={{visibility: 'hidden'}}>Edit</span>}
              </B.Text>
            </B.LeftRight>
            <Component
              active={active}
              userData={userData}
              config={config}
              setIndex={setIndex}
              myIndex={myIndex}
              currentIndex={currentIndex}
              meta={meta}
              checkoutName={checkoutName}
              checkout={checkout}
              step={step}
              {...props}
            />
          </B.Stack>
        )
      })}
    </B.Stack>
  )
}
