import {UseSearchReturn} from 'quickstart/hooks'
import {ReactNode, useCallback} from 'react'
import * as Final from 'react-final-form'
import {SearchResultTypes} from 'tizra'

interface SearchFormProps<T = SearchResultTypes> {
  children: ReactNode | ((props: Final.FormRenderProps) => ReactNode)
  instant?: boolean
  search: UseSearchReturn<T>
}

const InstantSearch = ({update}: Pick<UseSearchReturn<any>, 'update'>) => {
  const onChange = useCallback(
    ({dirty, values}: any) => {
      // Avoid firing onChange when we receive an update via initialValues.
      if (dirty) update(values)
    },
    [update],
  )
  return <Final.FormSpy onChange={onChange} />
}

export const SearchForm = <T = SearchResultTypes,>({
  children,
  instant = false,
  search: {params, update},
}: SearchFormProps<T>) => (
  <Final.Form
    initialValues={params}
    onSubmit={update}
    render={renderProps => (
      <form onSubmit={renderProps.handleSubmit}>
        {instant && <InstantSearch update={update} />}
        {typeof children === 'function' ? children(renderProps) : children}
      </form>
    )}
  />
)
